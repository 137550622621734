<template>
  <div>
    <div>
      <div class="d-flex align-items-center">
        <div>
          <h1 class="page-title">
            <a-icon type="arrow-left" @click="goToDistributor" />
            {{ $t('distributor.add_distributor') }}
          </h1>
        </div>
        <div>
          <a-breadcrumb class="page-link" separator=">">
            <a-breadcrumb-item href="">
              <a-icon type="home" @click="goToHome" />
            </a-breadcrumb-item>
            <a-breadcrumb-item href="">
              <span @click="goToDistributor">
                {{ $t('distributor.list_distributor') }}
              </span>
            </a-breadcrumb-item>
            <a-breadcrumb-item>
              {{ $t('distributor.add_distributor') }}
            </a-breadcrumb-item>
          </a-breadcrumb>
        </div>
      </div>
    </div>
    <a-card class="w-100 mt-4" :bordered="false">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item :label="$t('distributor.code')" prop="code">
          <a-input
            v-model.trim="form.code"
            size="large"
            autocomplete="off"
            :placeholder="$t('distributor.code')"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('distributor.name')" prop="name">
          <a-input
            v-model.trim="form.name"
            size="large"
            autocomplete="off"
            :placeholder="$t('distributor.name')"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('sideBar.productCategory')" prop="product_categories">
          <a-select
            v-model="form.product_categories"
            mode="multiple"
            size="large"
            class="select-antd-default"
            :placeholder="`${$t('utils.choose')} ${$t('sideBar.productCategory')}`"
            option-filter-prop="children"
            :filter-option="filterOption"
            :options="product_categories"
            show-search
            label-in-value
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="Email" prop="email">
          <a-input
            v-model.trim="form.email"
            size="large"
            autocomplete="off"
            placeholder="Email"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('distributor.mobile_phone')" prop="phone_number">
          <a-input
            v-model.trim="form.phone_number"
            size="large"
            addon-before="+62"
            :max-length="11"
            :placeholder="$t('distributor.mobile_phone')"
            allow-clear
            @keypress="numberOnly"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('distributor.npwp_id')" prop="taxid">
          <a-input
            v-model.trim="form.taxid"
            size="large"
            autocomplete="off"
            :placeholder="$t('distributor.npwp_id')"
            allow-clear
            @keypress="npwpNumber"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('distributor.npwp_name')" prop="taxname">
          <a-input
            v-model.trim="form.taxname"
            size="large"
            autocomplete="off"
            :placeholder="$t('distributor.npwp_name')"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('finance.bank_name')" prop="bank_name">
          <a-select
            v-model="form.bank_name"
            size="large"
            class="select-antd-default"
            :placeholder="`${$t('utils.choose')} ${$t('finance.bank_name')}`"
            option-filter-prop="children"
            :filter-option="filterOption"
            :options="banks"
            :disabled="!banks.length"
            show-search
            label-in-value
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('finance.account_number')" prop="account_number">
          <a-input
            v-model.trim="form.account_number"
            size="large"
            autocomplete="off"
            :placeholder="$t('finance.account_number')"
            allow-clear
            @keypress="numberOnly"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('finance.account_name')" prop="account_name">
          <a-input
            v-model.trim="form.account_name"
            size="large"
            autocomplete="off"
            :placeholder="$t('finance.account_name')"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('finance.payout_bank_name')" prop="payout_bank_name">
          <a-select
            v-model="form.payout_bank_name"
            size="large"
            class="select-antd-default"
            :placeholder="`${$t('utils.choose')} ${$t('finance.payout_bank_name')}`"
            option-filter-prop="children"
            :filter-option="filterOption"
            :options="banks"
            :disabled="!banks.length"
            show-search
            label-in-value
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('finance.payout_account_number')" prop="payout_account_number">
          <a-input
            v-model.trim="form.payout_account_number"
            size="large"
            autocomplete="off"
            :placeholder="$t('finance.payout_account_number')"
            allow-clear
            @keypress="numberOnly"
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('finance.payout_account_name')" prop="payout_account_name">
          <a-input
            v-model.trim="form.payout_account_name"
            size="large"
            autocomplete="off"
            :placeholder="$t('finance.payout_account_name')"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item label="Day SLA Complain" prop="sla_complain_day">
          <a-input
            v-model.trim="form.sla_complain_day"
            size="large"
            autocomplete="off"
            placeholder="Day SLA Complain"
            allow-clear
            @keypress="numberOnly"
          />
        </a-form-model-item>
        <div class="distributor-address">
          <a-row :gutter="20">
            <a-col :span="12">
              <label
                :title="$t('distributor.country')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.country') }}
              </label>
              <a-form-model-item prop="country">
                <a-select
                  v-model="form.country"
                  size="large"
                  class="select-antd-default"
                  :placeholder="$t('utils.choose') + $t('distributor.country')"
                  :options="countries"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <label
                :title="$t('distributor.city')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.city') }}
              </label>
              <a-form-model-item prop="city">
                <a-select
                  v-model="form.city"
                  size="large"
                  class="select-antd-default"
                  :placeholder="`${$t('utils.choose')} ${$t('distributor.city')}`"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  :options="cities"
                  :disabled="!cities.length"
                  show-search
                  label-in-value
                  allow-clear
                  @change="fetchArea($event, 'districts')"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="12">
              <label
                :title="$t('distributor.district')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.district') }}
              </label>
              <a-form-model-item prop="district">
                <a-spin :spinning="fetch">
                  <a-select
                    v-model="form.district"
                    size="large"
                    class="select-antd-default"
                    :placeholder="districtTitle"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    :options="districts"
                    :disabled="!districts.length"
                    show-search
                    label-in-value
                    allow-clear
                    @change="onChangeDistrict($event, 'districts')"
                  />
                </a-spin>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <label
                :title="$t('distributor.postal_code')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.postal_code') }}
              </label>
              <a-form-model-item prop="postal_code">
                <a-input
                  v-model.trim="form.postal_code"
                  size="large"
                  autocomplete="off"
                  :max-length="5"
                  :placeholder="postalTitle"
                  :disabled="!form.district"
                  allow-clear
                  read-only
                  @keypress="numberOnly"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="24">
              <label
                :title="$t('distributor.address')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.address') }}
              </label>
              <a-form-model-item prop="street">
                <a-textarea
                  v-model="form.street"
                  class="distributor-textarea"
                  size="large"
                  autocomplete="off"
                  :rows="4"
                  :placeholder="$t('distributor.address')"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <a-form-model-item :label="$t('distributor.warehouse_code')" prop="warehouse_code">
          <a-input
            v-model.trim="form.warehouse_code"
            size="large"
            autocomplete="off"
            :placeholder="$t('distributor.warehouse_code')"
            allow-clear
          />
        </a-form-model-item>
        <a-form-model-item :label="$t('distributor.warehouse_name')" prop="warehouse_name">
          <a-input
            v-model.trim="form.warehouse_name"
            size="large"
            autocomplete="off"
            :placeholder="$t('distributor.warehouse_name')"
            allow-clear
          />
        </a-form-model-item>
        <a-row :gutter="20">
          <a-col :span="4" class="pr-0 text-right">
            <label title="Coverage Area" class="ant-form-item-required">
              Coverage Area :
            </label>
          </a-col>
          <a-col :span="20">
            <a-select
              v-model="form.warehouse_area"
              mode="multiple"
              size="large"
              class="select-antd-default mb-2"
              option-filter-prop="children"
              :placeholder="`${$t('utils.choose')} ${$t('distributor.city')} Coverage Area`"
              :filter-option="filterOption"
              :options="cities"
              :disabled="!cities.length"
              show-search
              label-in-value
              allow-clear
              @blur="fetchCoverage"
              @change="onChangeCoverage"
            />
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="4">
            &nbsp;
          </a-col>
          <a-col :span="20">
            <div class="distributor-address">
              <a-form-model-item prop="warehouse_coverage">
                <a-spin :spinning="fetching">
                  <a-select
                    v-model="form.warehouse_coverage"
                    mode="multiple"
                    size="large"
                    class="select-antd-default"
                    option-filter-prop="children"
                    :placeholder="coverageTitle"
                    :filter-option="filterOption"
                    :options="coverages"
                    :disabled="!coverages.length"
                    show-search
                    label-in-value
                    allow-clear
                  />
                </a-spin>
              </a-form-model-item>
            </div>
          </a-col>
        </a-row>
        <h6>{{ $t('distributor.warehouse_address') }}</h6>
        <a-checkbox class="mt-3 mb-3" @change="onChangeAddress">
          {{ $t('distributor.use_distributor_address') }}
        </a-checkbox>
        <div class="distributor-address">
          <a-row :gutter="20">
            <a-col :span="12">
              <label
                :title="$t('distributor.country')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.country') }}
              </label>
              <a-form-model-item prop="warehouse_country">
                <a-select
                  v-model="form.warehouse_country"
                  size="large"
                  class="select-antd-default"
                  :placeholder="$t('utils.choose') + $t('distributor.country')"
                  :options="countries"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <label
                :title="$t('distributor.city')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.city') }}
              </label>
              <a-form-model-item prop="warehouse_city">
                <a-select
                  v-model="form.warehouse_city"
                  size="large"
                  class="select-antd-default"
                  :placeholder="`${$t('utils.choose')} ${$t('distributor.city')}`"
                  option-filter-prop="children"
                  :filter-option="filterOption"
                  :options="cities"
                  :disabled="!cities.length"
                  show-search
                  label-in-value
                  allow-clear
                  @change="fetchArea($event, 'warehouse_districts')"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="12">
              <label
                :title="$t('distributor.district')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.district') }}
              </label>
              <a-form-model-item prop="warehouse_district">
                <a-spin :spinning="warehouse_fetch">
                  <a-select
                    v-model="form.warehouse_district"
                    size="large"
                    class="select-antd-default"
                    :placeholder="warehouseDistrictTitle"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    :options="warehouse_districts"
                    :disabled="!warehouse_districts.length"
                    show-search
                    label-in-value
                    allow-clear
                    @change="onChangeDistrict($event, 'warehouse_districts')"
                  />
                </a-spin>
              </a-form-model-item>
            </a-col>
            <a-col :span="12">
              <label
                :title="$t('distributor.postal_code')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.postal_code') }}
              </label>
              <a-form-model-item prop="warehouse_postal_code">
                <a-input
                  v-model.trim="form.warehouse_postal_code"
                  size="large"
                  autocomplete="off"
                  :max-length="5"
                  :placeholder="warehousePostalTitle"
                  :disabled="!form.warehouse_district"
                  allow-clear
                  read-only
                  @keypress="numberOnly"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row :gutter="20">
            <a-col :span="24">
              <label
                :title="$t('distributor.address')"
                class="ant-form-item-required"
                style="line-height: 40px"
              >
                {{ $t('distributor.address') }}
              </label>
              <a-form-model-item prop="warehouse_street">
                <a-textarea
                  v-model="form.warehouse_street"
                  class="distributor-textarea"
                  size="large"
                  autocomplete="off"
                  :rows="4"
                  :placeholder="$t('distributor.address')"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>
        <div class="text-right">
          <a-button
            type="primary"
            size="large"
            class="mr-4"
            ghost
            :disabled="isLoading"
            @click="goToDistributor"
          >
            {{ $t('utils.cancel') }}
          </a-button>
          <a-button
            type="primary"
            size="large"
            :loading="isLoading"
            @click="onSubmitDistributor"
          >
            {{ $t('utils.save') }}
          </a-button>
        </div>
      </a-form-model>
    </a-card>
  </div>
</template>

<script>
import { addDistributor } from '@/api/channels/distributor'
import { getArea } from '@/api/data'
import { getDefaultWorkspaceRole } from '@/api/user'
import { getProductCategories } from '@/api/product'
import { getBankList } from '@/api/channels/distributor'
import { useInputNumber } from '@/composable/useInputNumber'
import { rulesDistributor } from '@/data/rules'

export default {
  name: 'CreateDistributor',
  setup() {
    const { numberOnly, npwpNumber } = useInputNumber()
    return { numberOnly, npwpNumber }
  },
  data() {
    return {
      fetch: false,
      warehouse_fetch: false,
      fetching: false,
      isLoading: false,
      businessId: Object.keys(this.$store.getters['user/businessListObjectByKey'])[0],
      rules: rulesDistributor,
      product_categories: [],
      banks: [],
      cities: [],
      districts: [],
      warehouse_districts: [],
      tempArea: [],
      coverages: [],
      countries: [{
        label: 'Indonesia', value: 228,
      }],
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      form: {
        workspace_role_id: '',
        code: '',
        name: '',
        product_categories: [],
        email: '',
        phone_number: '',
        taxid: '',
        taxname: '',
        bank_name: undefined,
        account_number: '',
        account_name: '',
        payout_bank_name: undefined,
        payout_account_number: '',
        payout_account_name: '',
        sla_complain_day: '',
        country: 228,
        city: undefined,
        district: undefined,
        postal_code: '',
        street: '',
        warehouse_code: '',
        warehouse_name: '',
        warehouse_area: [],
        warehouse_coverage: [],
        warehouse_country: 228,
        warehouse_city: undefined,
        warehouse_district: undefined,
        warehouse_postal_code: '',
        warehouse_street: '',
      },
    }
  },
  computed: {
    districtTitle() {
      return !this.districts.length
        ? this.$t('distributor.choose_city')
        : `${this.$t('utils.choose')} ${this.$t('distributor.district')}`
    },
    postalTitle() {
      return !this.form.district
        ? this.$t('distributor.choose_district')
        : `${this.$t('utils.choose')} ${this.$t('distributor.district')}`
    },
    warehouseDistrictTitle() {
      return !this.warehouse_districts.length
        ? this.$t('distributor.choose_city')
        : `${this.$t('utils.choose')} ${this.$t('distributor.district')}`
    },
    warehousePostalTitle() {
      return !this.form.warehouse_district
        ? this.$t('distributor.choose_district')
        : `${this.$t('utils.choose')} ${this.$t('distributor.district')}`
    },
    coverageTitle() {
      return !this.coverages.length
        ? this.$t('distributor.choose_coverage')
        : `${this.$t('utils.choose')} ${this.$t('distributor.district')} Coverage Area`
    },
  },
  created() {
    this.fetchWorkspaceRole()
    this.fetchProductCategory()
    this.fetchBank()
    this.fetchArea({ key: 228 }, 'cities')
  },
  methods: {
    async fetchWorkspaceRole() {
      this.isLoading = true
      await getDefaultWorkspaceRole(this.$route.query.workspace_id)
        .then(({ data }) => this.form.workspace_role_id = data.workspace_role_id || '')
        .catch(err => console.error(err))
        .finally(() => this.isLoading = false)
    },
    async fetchProductCategory() {
      this.isLoading = true
      await getProductCategories(this.businessId)
        .then(({ data }) => {
          this.product_categories = data.data.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          })
        })
        .catch(err => {
          this.product_categories = []
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    async fetchBank() {
      this.isLoading = true
      await getBankList()
        .then(({ data: { data: response } }) => {
          this.banks = response.contents.map(item => {
            return {
              label: item.bank_name,
              value: item.id,
            }
          })
        })
        .catch(err => {
          this.banks = []
          console.error(err)
        })
        .finally(() => this.isLoading = false)
    },
    async fetchArea(value, type) {
      const name = type.includes('warehouse') ? 'warehouse_' : ''
      this[`${name}fetch`] = true
      const bodyValue = {
        business_id: this.businessId,
        params: {
          parentId: value?.key || '',
        },
      }
      await getArea(bodyValue)
        .then(({ data: { list } }) => {
          this[type] = list
        })
        .catch(err => {
          this[type] = []
          console.error(err)
        })
        .finally(() => {
          if (type.includes('districts')) {
            this.form[`${name}district`] = undefined
            this.form[`${name}postal_code`] = ''
          }
          this[`${name}fetch`] = false
        })
    },
    fetchCoverage(value) {
      this.form.warehouse_coverage = undefined
      if (value.length) {
        this.fetching = true
        this.coverages = []
        value.forEach(async (item) => {
          const bodyValue = {
            business_id: this.businessId,
            params: {
              parentId: item?.key || '',
            },
          }
          await getArea(bodyValue)
            .then(({ data: { list } }) => {
              const newList = list.map(element => {
                return { ...element, value: `${item.key}-${element.value}` }
              })
              this.coverages = this.coverages.concat(newList)
            })
            .catch(err => {
              this.coverages = []
              console.error(err)
            })
            .finally(() => this.fetching = false)
        })
      } else {
        this.coverages = []
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().includes(input.toLowerCase())
      )
    },
    onChangeDistrict(value, type) {
      const postcode = value && value.key ? this[type].find(item => item.value === value.key).postcode : ''
      const name = type.includes('warehouse') ? 'warehouse_' : ''
      this.form[`${name}postal_code`] = postcode
      if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate(`${name}postal_code`)
    },
    onChangeAddress(value) {
      if (value) {
        this.warehouse_districts = this.districts
        this.form.warehouse_city = this.form.city
        this.form.warehouse_district = this.form.district
        this.form.warehouse_postal_code = this.form.postal_code
        this.form.warehouse_street = this.form.street
        if (this.$refs.ruleForm) {
          this.$refs.ruleForm.clearValidate('warehouse_city')
          this.$refs.ruleForm.clearValidate('warehouse_district')
          this.$refs.ruleForm.clearValidate('warehouse_postal_code')
          this.$refs.ruleForm.clearValidate('warehouse_street')
        }
      }
    },
    onChangeCoverage(value) {
      if (!value.length) {
        this.coverages = []
        this.form.warehouse_coverage = []
      }
    },
    onSubmitDistributor() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          let coverage_areas = []
          const {
            code, name, email, phone_number, sla_complain_day, product_categories,
            taxid, taxname, city, district, street, postal_code, warehouse_name,
            warehouse_code, warehouse_city, warehouse_district, warehouse_street,
            warehouse_postal_code, warehouse_area, warehouse_coverage, bank_name,
            account_number, account_name, workspace_role_id, payout_bank_name, payout_account_number,
            payout_account_name,
          } = this.form

          warehouse_area.forEach(item => {
            coverage_areas.push({
              level: 'PROVINCE',
              id: +item.key,
              name: item.label.split('-')[1].trim(),
            })
            warehouse_coverage.forEach(element => {
              if (element.key.includes(`${item.key}-`)) {
                coverage_areas.push({
                  level: 'CITY',
                  id: +element.key.split('-')[1],
                  name: item.label.split('-')[0].trim(),
                })
              }
            })
          })

          const bodyValue = {
            businessId: this.businessId,
            workspaceId: this.$route.query.workspace_id,
            channelId: this.$route.params.id,
            data: {
              workspace_role_id,
              code,
              name,
              email,
              sla_complain_day: +sla_complain_day,
              phone_number: '0' + phone_number,
              product_categories: product_categories.map(item => {
                return {
                  id: item.key,
                  name: item.label,
                }
              }),
              taxpayer: {
                id: taxid,
                name: taxname,
              },
              address: {
                area_id: +district.key,
                country: {
                  id: 228,
                  name: 'Indonesia',
                },
                province: {
                  id: +city.key,
                  name: city.label.split('-')[1].trim(),
                },
                city: {
                  id: +city.key,
                  name: city.label.split('-')[0].trim(),
                },
                district: {
                  id: +district.key,
                  name: district.label.split('-')[1].trim(),
                },
                sub_district: {
                  id: +district.key,
                  name: district.label.split('-')[0].trim(),
                },
                street,
                postal_code: +postal_code,
              },
              warehouse: {
                name: warehouse_name,
                code: warehouse_code,
                address: {
                  area_id: +warehouse_district.key,
                  country: {
                    id: 228,
                    name: 'Indonesia',
                  },
                  province: {
                    id: +warehouse_city.key,
                    name: warehouse_city.label.split('-')[1].trim(),
                  },
                  city: {
                    id: +warehouse_city.key,
                    name: warehouse_city.label.split('-')[0].trim(),
                  },
                  district: {
                    id: +warehouse_district.key,
                    name: warehouse_district.label.split('-')[1].trim(),
                  },
                  sub_district: {
                    id: +warehouse_district.key,
                    name: warehouse_district.label.split('-')[0].trim(),
                  },
                  street: warehouse_street,
                  postal_code: +warehouse_postal_code,
                },
                coverage_areas,
              },
              bank_accounts: [{
                bank_id: bank_name.key,
                bank_name: bank_name.label,
                number: account_number,
                name: account_name,
              }],
              payout_bank_accounts: [{
                bank_id: payout_bank_name.key,
                // hardcode paycout channel id temporary
                payout_channel_id: 'ccc2a794-5c82-42d0-9713-be293ee0d969',
                bank_name: payout_bank_name.label,
                number: payout_account_number,
                name: payout_account_name,
                is_default: false,
                is_new: true,
              }],
            },
          }
          
          await addDistributor(bodyValue)
            .then(response => {
              this.$notification.success({
                message: 'Berhasil',
                description: 'Distributor Berhasil ditambahkan',
              })
              console.log('response', response)
              this.goToDistributor()
            })
            .catch(err => {
              this.$notification.error({
                message: 'Gagal',
                description: 'Distributor Gagal ditambahkan',
              })
              console.error(err)
            })
            .finally(() => this.isLoading = false)
        }
      })
    },
    goToDistributor() {
      this.$router.push({
        path: `/distributor-channel/${this.$route.params.id}/distributor-list`,
        query: { ...this.$route.query },
      })
    },
    goToHome() {
      this.$router.push({
        path: '/dashboard',
        query: { ...this.$route.query },
      })
    },
  },
}
</script>

<style lang="scss">
.page-title {
  margin-bottom: 0;
  margin-right: 60px;
  font-size: 1.5rem;
  font-weight: bold;

  i {
    margin-right: 1rem;
    font-size: 0.75rem;
    vertical-align: middle;
    cursor: pointer;
  }
}
.page-link {
  background-color: transparent !important;
  border-color: transparent !important;

  > span {
    > span {
      color: #9DA3AA;
    }

    a.ant-breadcrumb-link {
      color: #2196F3 !important;
    }
  }
}
.distributor-address .ant-col-20 {
  width: 100%;
}
.distributor-textarea {
  resize: none;
}
.select-antd-default.ant-select-lg .ant-select-selection__rendered {
  line-height: 38px !important;
}
</style>
