import apiClient from '@/services/axios'

export const getCustomers = ({ business_id, params }) => {
  return apiClient({
    method: 'GET',
    url: '/channel/distributor/user/v2/query/customers',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getCustomerDetail = async ({ business_id, customer_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/user/v2/query/customers/${customer_id}`,
    headers: {
      'Business-Id': business_id,
    },
    notifications: true,
  })
}

export const getPaymentModel = async ({ business_id, channel_id, seller_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/payment_provider/query/seller/term/${channel_id}/${seller_id || ''}`,
    headers: {
      'Business-Id': business_id,
    },
    notifications: true,
  })
}

export const getSalesCode = async ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: '/channel/distributor/user/v2/query/sales/person',
    headers: {
      'Business-Id': business_id,
    },
    params,
    notifications: true,
  })
}

export const getSeller = ({ businessId, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/warehouse/query/find',
    headers: {
      'Business-Id': businessId,
    },
    params,
  })
}

export const getBuyerPayment = ({ business_id, buyer_id, seller_id }) => {
  return apiClient({
    method: 'get',
    url: '/channel/distributor/payment/query/buyer-payment-term-after-delivery',
    headers: {
      'Business-Id': business_id,
      'Buyer-Id': buyer_id,
      'Seller-Id': seller_id,
    },
    notifications: true,
  })
}

export const getBuyerPaymentProvider = ({ business_id, buyer_id, seller_id, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/payment_provider/query/buyer/term/${channel_id}/${seller_id}`,
    headers: {
      'Business-Id': business_id,
      'Buyer-Id': buyer_id,
    },
    notifications: true,
  })
}

export const getBalance = ({ businessId, sellerId }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment/query/wallet/${sellerId}/balance`,
    headers: {
      'Business-Id': businessId,
    },
  })
}

export const getWithdrawal = ({ businessId, sellerId, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/payment/query/wallet/withdraw/history',
    headers: {
      'Business-Id': businessId,
      'Seller-Id': sellerId,
    },
    params,
  })
}

export const getWithdrawalParameter = ({ businessId, sellerId }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/payment/query/wallet/withdraw/parameter',
    headers: {
      'Business-Id': businessId,
      'Seller-Id': sellerId,
    },
  })
}

export const getBankAccount = ({ businessId, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/payment_provider/query/bank/account/list',
    headers: {
      'Business-Id': businessId,
    },
    params,
  })
}

export const getBankSeller = () => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/payment_provider/query/seller/bank/list?type=PAYOUT',
  })
}

export const getBankSellerBySellerId = ({ sellerId }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment_provider/query/seller/bank/${sellerId}`,
  })
}

export const getBankList = () => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/payment_provider/query/bank/list',
    params: {
      page: 1,
      limit: 3000,
    },
  })
}

export const getTransferList = ({ businessId, sellerId, id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment_confirmation/query/bills/list/${id}/BANK_TRANSFER`,
    headers: {
      'Business-Id': businessId,
      'Seller-Id': sellerId,
    },
    params,
  })
}

export const getTransferProof = ({ businessId, id, paymentId }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment_confirmation/query/proof/payment/${id}/${paymentId}`,
    headers: {
      'Business-Id': businessId,
    },
  })
}

export const getInvoiceByOrder = (order_id) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment/query/internal/payment-order-summary/order/${order_id}`,
  })
}

export const getDetailOrderByOrderNo = ({ id, sellerId, businessId, orderNo }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment_confirmation/query/bills/detail/${id}/type/COD/order/${orderNo}`,
    headers: {
      'Business-Id': businessId,
      'Seller-Id': sellerId,
    },
  })
}

export const getArea = ({ id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/price/query/${id}/price-area-list`,
    params,
  })
}

export const getPriceArea = ({ business_id, id, params }) => {
  return apiClient({
    method: 'get',
      url: `channel/distributor/price/query/v2/powerbiz/${id}`,
      headers: {
        'Business-Id': business_id,
      },
      params,
  })
}

export const getDistributor = ({ headers, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/user/v2/query/distributors',
    headers,
    params,
  })
}

export const getDistributorPriceArea = ({ business_id, channel_id, area_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/user/v2/query/warehouse/map/price-area/${channel_id}/${area_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getWarehouseBySeller = ({ business_id, seller_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/user/v2/query/distributors/${seller_id}/warehouses`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getDetailDistributor = (seller_id) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/app/user/v2/query/distributors/${seller_id}`,
  })
}

export const getDetailOrderCAD = ({ channel_id, order_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/app/payment/query/bills/detail/order/${channel_id}/${order_id}`,
  })
}

export const getListBillCustomerBySellerId = ({ channel_id, seller_id, buyer_id, params }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/app/payment/query/creditlimit/transaction/${channel_id}/${seller_id}/unpaid`,
    params,
    headers: {
      'Buyer-Id': buyer_id,
    },
  })
}
// 
export const getCustomerCategory = ({ business_id, params = undefined }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/user/v2/query/customers/category',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getCustomer = ({ headers, params}) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/user/v2/query/customers',
    headers,
    params,
  })
}

export const cekVoucherName = ({ headers, params}) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/promotion/query/is-valid',
    headers,
    params,
  })
}

export const getProductBrand = ({ id, business_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/product/query/${id}/brands`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getProduct = ({ id, businessId, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/product/query/powerbiz/${id}`,
    headers: {
      'Business-Id': businessId,
    },
    params,
  })
}

export const getPromo = ({ channel_id, promo_id = undefined, params }) => {
  const path = promo_id ? `/${promo_id}` : ''
  return apiClient({
    method: 'get',
    url: `channel/distributor/promotion/query/${channel_id + path}`,
    params,
  })
}

export const getCatalogName = ({ id, businessId, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/product/query/internal/${id}/catalogs`,
    headers: {
      'Business-Id': businessId,
    },
    params,
  })
}

export const getDetailProductRedeem = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url : 'channel/distributor/product/cmd/api-composer/product-redeem',
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getProductDistributorCategories = (business_id) => {
  return apiClient({
    method: 'get',
    url : '/channel/distributor/product/query/categories',
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getPriceTemplate = ({ business_id, channel_id, params }) => {
  return apiClient({
    method: 'get',
    responseType: 'blob',
    url : `/channel/distributor/price/query/${channel_id}/export-template`,
    headers: {
      'Business-Id': business_id,
    },
    params,
    notifications: true,
  })
}

export const getReturList = ({ business_id, channel_id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/return/query/list/${channel_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getFinanceDetail = ({ business_id, channel_id, order_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/order/query/${channel_id}/details/${order_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getPaymentDetail = ({ business_id, channel_id, order_id }) => {
  return apiClient({
    method: 'get',
    url: `/channel/distributor/payment_provider/query/order/${channel_id}/${order_id}`,
    headers: {
      'Business-Id': business_id,
    },
  })
}

export const getPaymentProvider = ({ business_id, buyer_id, id, channel_id }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment_provider/query/method/${channel_id}/${id}`,
    headers: {
      'Business-Id': business_id,
      'Buyer-Id': buyer_id,
    },
  })
}

export const getPaymentHistory = ({ business_id, id, seller_id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/payment/query/history/seller/${id}/${seller_id}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const getWalletHistory = ({ business_id, seller_id, params }) => {
  return apiClient({
    method: 'get',
    url: 'channel/distributor/payment/query/internal/wallet/history',
    headers: {
      'Business-Id': business_id,
      'Seller-Id': seller_id,
    },
    params,
  })
}

export const getCreditNotes = ({ business_id, params }) => {
  return apiClient({
    method: 'get',
    url: `channel/distributor/loyalty/query/creditnote/`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const GETPRODUCT = async({ business_id, channel, channelId, params }) => {
  let data = []
  let props = { all: 0, total_row: 0 }
  const isDistributor = channel === 'distributor'
  const headers = {
    'Business-Id': business_id,
  }
  const { data: response } = await apiClient({
    method: 'get',
    url: `/channel/distributor/product/query/${isDistributor ? 'powerbiz/': ''}${channelId}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })

  const { data: res, ...obj } = response || {}
  obj.all = obj.active + obj.notActive // ???

  /** @NOTE : Get price */
  if (res?.length) {
    await apiClient({
      method: 'get',
      url: `/channel/distributor/price/query/v2/powerbiz/${channelId || ''}`,
      params: {
        // business_id,
        product_id: res.map(f => f.id || f.product_id).join(','),
        effective_date: new Date().toISOString(),
      },
      headers,
    })
    .then(result => {
      const resData = result?.data?.data
      data = resData.length ? res.map(item => {
        const range_price = resData.find(f => f.product_id === item.id || f.product_id === item.product_id)?.range_price
        if (range_price) {
          return {
            ...item,
            range_price,
          }
        }
        return item
      }) : res
      props = obj
    })
    .catch(() => {
      data = res || []
      props = obj || {}
    })
  }
  else {
    data = res || []
    props = obj || {}
  }
  
  return Promise.resolve({ data: data, ...props})
}

export const addDistributor = ({ businessId, workspaceId, channelId, data }) => {
  return apiClient({
    method: 'post',
    url: 'channel/distributor/user/v2/cmd/distributors',
    headers: {
      'Business-Id': businessId,
      'Workspace-Id': workspaceId,
      'Channel-Id': channelId,
    },
    data,
  })
}

export const createBankSeller = ({ channelId, businessId, payload }) => {
  return apiClient({
    method: 'post',
    url: `channel/distributor/payment_provider/cmd/seller/bank/create/${channelId}`,
    data: payload,
    headers: {
      'Business-Id': businessId,
    },
  })
}

export const addBank = ({ id, businessId, data }) => {
  return apiClient({
    method: 'post',
    url: `channel/distributor/payment_provider/cmd/bank/account/create/${id}`,
    headers: {
      'Business-Id': businessId,
    },
    data,
  })
}

export const addWithdrawal = ({ businessId, sellerId, data }) => {
  return apiClient({
    method: 'post',
    url: `channel/distributor/payment/cmd/wallet/${sellerId}/withdraw`,
    headers: {
      'Business-Id': businessId,
    },
    data,
  })
}

export const addTransfer = ({ businessId, id, paymentId, data }) => {
  return apiClient({
    method: 'post',
    url: `channel/distributor/payment_confirmation/cmd/confirmation/payment/${id}/${paymentId}`,
    headers: {
      'Business-Id': businessId,
    },
    data,
  })
}

export const uploadPrice = ({ channel_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/distributor/price/cmd/${channel_id}/upload`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data,
    notifications: true,
  })
}

export const CREATEPRODUCTREDEEM = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/distributor/product/cmd/api-composer/product-redeem`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const CREATEPRODUCTDISTRIBUTORSHIP = ({ business_id, data }) => {
  return apiClient({
    method: 'post',
    url: `/channel/distributor/product/cmd/api-composer/product`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const crudCreditNote = ({ business_id, type, data }) => {
  return apiClient({
    method: 'post',
    url: `channel/distributor/loyalty/cmd/creditnote/${type}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const addDiscount = ({ method, business_id, id, data }) => {
  const update = method === 'put' ? '/update' : ''
  return apiClient({
    method,
    url: `channel/distributor/promotion/cmd/${id + update}`,
    data,
    params: { business_id },
    notifications: true,
  })
}

export const approveCustomer = ({ business_id, store_code, params }) => {
  return apiClient({
    method: 'put',
    url: `/channel/distributor/user/v2/cmd/approval/${store_code}`,
    headers: {
      'Business-Id': business_id,
    },
    params,
  })
}

export const approvePromo = ({ business_id, channel_id, action, data }) => {
  return apiClient({
    method: 'put',
    url: `channel/distributor/promotion/cmd/${channel_id}/${action}`,
    data,
    params: { business_id },
  })
}

export const rejectTransferProof = ({ id, paymentId, businessId }) => {
  return apiClient({
    method: 'put',
    url: `channel/distributor/payment_confirmation/cmd/reject/payment/${id}/${paymentId}`,
    headers: {
      'Business-Id': businessId,
    },
  })
}

export const crudCustomer = ({ business_id, customer_id, data }) => {
  const path = customer_id ? `/${customer_id}` : ''
  return apiClient({
    method: customer_id ? 'put' : 'post',
    url: `/channel/distributor/user/v2/cmd/customer${path}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
    notifications: true,
  })
}

export const updateCustomerStatus = ({ type, customer_id }) => {
  return apiClient({
    method: 'put',
    url: `/channel/distributor/user/v2/cmd/customer/${type}/${customer_id}`,
  })
}

export const updatePriceArea = ({ id, workspace_id, data, channel_id }) => {
  return apiClient({
    method: 'put',
    url: `channel/distributor/price/cmd/${channel_id}/product/${id}`,
    headers: {
      'Workspace-Id': workspace_id,
    },
    data,
  })
}

export const updateStorePriceArea = ({ method, headers, data, channel_id }) => {
  return apiClient({
    method,
    url: `/channel/distributor/price/cmd/${channel_id}/pricearea`,
    headers,
    data,
  })
}

export const updateProduct = ({ channel_id, business_id, data }) => {
  return apiClient({
    method: 'put',
      url: `channel/distributor/product/cmd/${channel_id}/update`,
      headers: {
        'Business-Id': business_id,
      },
      data,
  })
}

export const cancelDiscount = ({ id, workspaceId, businessId, data }) => {
  return apiClient({
    method: 'put',
    url: `channel/distributor/promotion/cmd/${id}/cancel?business_id=${businessId}`,
    headers: {
      'Workspace-Id': workspaceId,
    },
    data,
  })
}

export const updateReturStatus = ({ business_id, channel_id, return_id, status, data }) => {
  return apiClient({
    method: 'put',
    url: `channel/distributor/return/cmd/update/${channel_id}/${return_id}/${status}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
  })
}

export const updateDistributorshipState = ({ business_id, type, data, params }) => {
  return apiClient({
    method: 'put',
    url: `channel/distributor/order/cmd/${type}`,
    headers: {
      'Business-Id': business_id,
    },
    data,
    params,
  })
}

export const deleteCustomerCart = ({ business_id, buyer_id, channel_id }) => {
  return apiClient({
    method: 'delete',
    url: `/channel/distributor/order/cmd/${channel_id}/buyer/${buyer_id}`,
    headers: {
      'Business-Id': business_id,
      'Buyer-Id': buyer_id,
    },
    notifications: true,
  })
}