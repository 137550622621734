import { i18n } from '@/i18n'

const checkEmail = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(`${i18n.t('customer.rules.email')}`))
  } else {
    const mailformat = /^\w+([\\.+-]?\w+)*@\w+([\\.+-]?\w+)*(\.\w{1,10})+$/
    if (value.match(mailformat)) {
      callback()
    } else {
      callback(new Error(`${i18n.t('customer.rules.email_invalid')}`))
    }
  }
}

const checkNumber = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('Inputan harus diisi'))
  } else if (value == '0') {
    callback(new Error('Inputan tidak boleh 0'))
  } else {
    callback()
  }
}

export const rulesSyncBlibli = {
  id: [{
    required: true,
    message: i18n.t('store_list_page.rules.store_id'),
    trigger: 'blur',
  }],
  api: [{
    required: true,
    message: i18n.t('store_list_page.rules.api_password'),
    trigger: 'blur',
  }],
}

export const rulesWarehouseBlibli = {
  warehouse: [{
    required: true,
    message: i18n.t('store_list_page.rules.main_warehouse'),
    trigger: 'change',
  }],
  pickup: [{
    required: true,
    message: i18n.t('store_list_page.rules.channel_warehouse'),
    trigger: 'change',
  }],
}

export const rulesDistributor = {
  name: [{
    required: true,
    message: i18n.t('distributor.rules.name'),
    trigger: 'blur',
  }],
  product_categories: [{
    type: 'array',
    required: true,
    message: i18n.t('distributor.rules.categories'),
    trigger: 'change',
  }],
  email: [{
    required: true,
    validator: checkEmail,
    trigger: 'blur',
  }],
  phone_number: [{
    required: true,
    message: i18n.t('distributor.rules.phone_number'),
    trigger: 'blur',
  }],
  taxid: [{
    required: true,
    message: i18n.t('distributor.rules.taxid'),
    trigger: 'blur',
  }],
  taxname: [{
    required: true,
    message: i18n.t('distributor.rules.taxname'),
    trigger: 'blur',
  }],
  bank_name: [{
    required: true,
    message: i18n.t('distributor.rules.bank_name'),
    trigger: 'change',
  }],
  account_number: [{
    required: true,
    message: i18n.t('distributor.rules.account_number'),
    trigger: 'blur',
  }],
  account_name: [{
    required: true,
    message: i18n.t('distributor.rules.account_name'),
    trigger: 'blur',
  }],
  sla_complain_day: [{
    required: true,
    message: i18n.t('distributor.rules.sla_shipping_day'),
    trigger: 'blur',
  }],
  city: [{
    required: true,
    message: i18n.t('distributor.rules.city'),
    trigger: 'blur',
  }],
  district: [{
    required: true,
    message: i18n.t('distributor.rules.district'),
    trigger: 'blur',
  }],
  postal_code: [{
    required: true,
    message: i18n.t('distributor.rules.postal_code'),
    trigger: 'blur',
  }],
  street: [{
    required: true,
    message: i18n.t('distributor.rules.address'),
    trigger: 'blur',
  }],
  warehouse_name: [{
    required: true,
    message: i18n.t('distributor.rules.warehouse_name'),
    trigger: 'blur',
  }],
  warehouse_coverage: [{
    required: true,
    message: i18n.t('distributor.rules.coverage'),
    trigger: 'blur',
  }],
  warehouse_city: [{
    required: true,
    message: i18n.t('distributor.rules.city'),
    trigger: 'blur',
  }],
  warehouse_district: [{
    required: true,
    message: i18n.t('distributor.rules.district'),
    trigger: 'blur',
  }],
  warehouse_postal_code: [{
    required: true,
    message: i18n.t('distributor.rules.postal_code'),
    trigger: 'blur',
  }],
  warehouse_street: [{
    required: true,
    message: i18n.t('distributor.rules.address'),
    trigger: 'blur',
  }],
}

export const rulesBank = {
  bank_id: [{
    required: true,
    message: 'Bank harus dipilih',
    trigger: 'change',
  }],
  account_number: [{
    required: true,
    message: 'No. Rekening harus diisi, harus mengandung setidaknya 7 karakter',
    trigger: 'blur',
    min: 7,
  }],
  account_name: [{
    required: true,
    message: 'Nama Akun harus diisi, harus mengandung setidaknya 3 karakter',
    trigger: 'blur',
    min: 3,
  }],
}

export const rulesRequest = {
  bank: [{
    required: true,
    message: 'Bank harus dipilih',
    trigger: 'change',
  }],
  amount: [{
    required: true,
    validator: checkNumber,
    trigger: 'change',
  }],
  correct: [{
    type: 'array',
    required: true,
    message: 'Data sesuai harus dipilih',
    trigger: 'change',
  }],
}

export const rulesPriceArea = {
  price_area_id: [{
    required: true,
    message: 'Area harus dipilih',
    trigger: 'change',
  }],
  customer_category_id: [{
    required: true,
    message: 'Tipe Pelanggan harus dipilih',
    trigger: 'change',
  }],
  value: [{
    required: true,
    message: 'Harga harus diisi',
    trigger: 'blur',
  }],
  uom: [{
    required: true,
    message: 'Satuan harus dipilih',
    trigger: 'change',
  }],
  effective_date: [{
    required: true,
    message: 'Tanggal Berlaku harus dipilih',
    trigger: 'change',
  }],
}

export const rulesPromotion = {
  min: [{
    required: true,
    validator: checkNumber,
    trigger: 'blur',
  }],
  max: [{
    required: true,
    validator: checkNumber,
    trigger: 'blur',
  }],
  uom: [{
    required: true,
    message: 'UoM harus dipilih',
    trigger: 'change',
  }],
}

export const rulesGift = {
  value_type: [{
    required: true,
    message: 'Format Diskon harus dipilih',
    trigger: 'change',
  }],
  value: [{
    required: true,
    validator: checkNumber,
    trigger: 'blur',
  }],
  max_value: [{
    required: true,
    validator: checkNumber,
    trigger: 'blur',
  }],
  currency: [{
    required: true,
    message: 'Mata Uang harus dipilih',
    trigger: 'change',
  }],
  qty: [{
    required: true,
    validator: checkNumber,
    trigger: 'blur',
  }],
  uom: [{
    required: true,
    message: 'UoM harus dipilih',
    trigger: 'change',
  }],
  catalog_id: [{
    required: true,
    message: 'Produk harus dipilih',
    trigger: 'change',
  }],
}

export const rulesVoucher = {
  code: [{
    required: true,
    message: 'Kode Voucher harus diisi',
    trigger: 'blur',
  }],
  start_until: [{
    required: true,
    message: 'Waktu Mulai harus dipilih',
    trigger: 'change',
  }],
  end_until: [{
    required: true,
    message: 'Waktu Berakhir harus dipilih',
    trigger: 'change',
  }],
}

export const rulesDiscount = {
  promotion_name: [{
    required: true,
    message: 'Nama Program harus diisi',
    trigger: 'blur',
  }],
  start_date: [{
    required: true,
    message: 'Waktu Mulai harus dipilih',
    trigger: 'change',
  }],
  end_date: [{
    required: true,
    message: 'Waktu Berakhir harus dipilih',
    trigger: 'change',
  }],
  attribute: [{
    required: true,
    message: 'Tipe Promosi harus dipilih',
    trigger: 'change',
  }],
  tier: [{
    required: true,
    message: 'Tingkat Diskon harus dipilih',
    trigger: 'change',
  }],
  type: [{
    required: true,
    message: 'Tipe Program harus dipilih',
    trigger: 'change',
  }],
  typePromo: [{
    required: true,
    message: 'Tipe Kriteria Promo harus dipilih',
    trigger: 'change',
  }],
  quota: [{
    required: true,
    trigger: 'blur',
  }],
}

export const rulesAddPriceArea = {
  area_name: [{
    required: true,
    message: 'Nama Harga per Area harus diisi',
    trigger: 'blur',
  }],
}

export const rulesCustomer = {
  email: [{
    required: true,
    validator: checkEmail,
    trigger: 'change',
  }],
  // mobile: [{
  //   required: true,
  //   message: i18n.t('customer.rules.mobile'),
  //   trigger: 'blur',
  // }],
  storeName: [{
    required: true,
    message: i18n.t('customer.rules.name'),
    trigger: 'blur',
  }],
  namecustomer: [{
    required: true,
    message: i18n.t('customer.rules.namecustomer'),
    trigger: 'blur',
  }],
  phone: [{
    required: true,
    message: i18n.t('customer.rules.mobile'),
    trigger: 'blur',
  }],
  password: [{
    required: true,
    message: i18n.t('customer.rules.password'),
    trigger: 'change',
  }],
  confirmpassword: [{
    required: true,
    message: i18n.t('customer.rules.confirm_password'),
    trigger: 'blur',
  }],
  category: [{
    required: true,
    message: i18n.t('customer.rules.category'),
    trigger: 'change',
  }],
  line1: [{
    required: true,
    message: 'Nama jalan harus diisi',
    trigger: 'blur',
  }],
  country: [{
    required: true,
    message: 'Negara harus dipilih',
    trigger: 'blur',
  }],
  city: [{
    // type: 'array',
    required: true,
    message: 'Kota harus diisi', // i18n.t('customer.rules.city')
    trigger: 'blur', // change
  }],
  subdistrict: [{
    required: true,
    message: 'Kecamatan harus dipilih',
    trigger: 'blur',
  }],
  postcode: [{
    required: true,
    message: 'Kode Pos harus diisi',
    trigger: 'blur',
  }],
  city_distributor: [{
    type: 'array',
    required: true,
    message: i18n.t('customer.rules.city'),
    trigger: 'change',
  }],
  distributor: [{
    type: 'array',
    required: true,
    message: i18n.t('customer.rules.distributor'),
    trigger: 'change',
  }],
  sales_code: [{
    required: true,
    message: i18n.t('customer.rules.sales_code'),
    trigger: 'change',
  }],
  coin_usage: [{
    required: true,
    message: i18n.t('customer.rules.coin_usage'),
    trigger: 'change',
  }],
  payment_model: [{
    required: true,
    trigger: 'change',
  }],
  coin_flag: [{
    required: true,
    message: i18n.t('customer.rules.coin_usage'),
    trigger: 'change',
  }],
  payment_model: [{
    required: true,
    message: 'Model pembayaran harus di isi',
    trigger: 'change',
  }],
  credit_limit: [{
    required: true,
    message: 'Kredit limit harus di isi',
    trigger: 'change',
  }],
  // distributor: [{
  //   required: true,
  //   message: i18n.t('customer.rules.distributor'),
  //   trigger: 'change',
  // }],
}
