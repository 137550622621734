import { apiClientFastify } from '@/services/axios'

export const getArea = ({ businessId, params }) => {
  return apiClientFastify({
    method: 'get',
    url: '/data/areaparent',
    headers: {
      'Business-Id': businessId,
    },
    params,
  })
}

export const getAreaForPriceArea = ({ businessId, params, url }) => {
  return apiClientFastify({
    method: 'get',
    url,
    headers: {
      'Business-Id': businessId,
    },
    params,
  })
}

export const getListArea = (params) => {
  return apiClientFastify({
    method: 'get',
    url: '/data/areaparent',
    params,
  })
}